











































import { formatRupiah } from "@/apps/core/modules/utils/text";
import { defineComponent } from "@vue/composition-api";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import { ModalProgrammatic as modal } from "buefy";

export default defineComponent({
  name: "CicilanListBox",
  props: {
    cicilan: { type: Object, required: true },
  },
  setup(props) {
    const showImage = () => {
      return modal.open(
        `<p class="image"><img src="${props.cicilan.bukti}"></p>`
      );
    };
    return {
      // Data
      detailUrl: "/cicilan",

      // Method
      formatRupiah,
      showImage,
      toUserDateTimeFormat,
    };
  },
});
